<template>
  <div class="list">
    <div class="search">
      <img src="@images/faqs/pic1.png" alt="" class="bg" />
      <p class="name">What are you looking for?</p>
      <div class="input">
        <img src="@images/faqs/search.svg" alt="" />
        <input type="text" v-model="search" placeholder="Search in articles" />
        <button @click="getFAQ">search</button>
      </div>
    </div>
    <ul v-loading="loading">
        <li v-for="item in list" :key="item.id" @click="articel=item; visible = true">
            <p class="name">{{item.title}}</p>
            <p class="text" v-if="item.short_description !== null">
            {{item.short_description}}
            </p>
            <p v-else class="text" v-html="item.content">
            </p>
        </li>
    </ul>
    <el-dialog v-model="visible" :modal="true" :footer="null" custom-class="modal" :width="'90%'" :top="'5vh'" center>
      <div class="conten">
        <p class="title">{{articel.title}}</p>
        <p class="text" v-html="articel.content"></p>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      search: "",
      articel: {
        title: null,
        content: null
      },
      visible: false,
      loading: false,
      list: [],
      page: 1,
      page_size: 20,
      pagination: { pages: 0, total: 0, position: 'both', onChange: (page, pageSize) => this.changePage(page, pageSize) },
    };
  },
  methods: {
    async getFAQ() {
        this.loading = true;
        const res = await this.axios.get(`/base/topics`, {params:{q: this.search, page: this.page, page_size: this.page_size}});
        this.loading = false;
        try{
            const data = res.data;
            this.list = data.items;
            this.page = data.page;
            this.pagination.pageSize = data.page_size;
            this.pagination.pages = data.pages;
            this.pagination.total = data.total;
            this.pagination.current = data.page
        }
        catch(ex){
            console.log(ex)
        }
    },
    changePage(page, pageSize){
      this.page = page
      this.pagination.pageSize = pageSize
      this.getFAQ()
    }
  },
  created() {
    this.getFAQ();
  },
};
</script>
<style lang="scss" scoped>
input {
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
.list {
  .search {
    position: relative;
    width: 100%;
    height: 136px;
    background-color: #9daab3;
    padding-left: 42px;
    .bg {
      position: absolute;
      right: 0;
      top: 0;
    }
    .name {
      font-size: 22px;
      color: #e9edee;
      padding-top: 19px;
    }
    .input {
      position: relative;
      width: 673px;
      height: 50px;
      margin-top: 16px;
      @include flex(center, center);
      border: 2px solid #e9edee;
      border-radius: 4px;
      z-index: 1;
      img {
        margin-left: 11px;
      }
      input {
        width: 556px;
        height: 100%;
        background-color: #9daab3;
        padding-left: 13px;
        &::placeholder {
          color: #e9edee;
        }
      }
      button {
        flex: 1;
        height: 100%;
        cursor: pointer;
        border-radius: 2px;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
  ul {
      li {
        width: 100%;
        height: 113px;
        border: 1px solid #9daab3;
        padding-left: 42px;
        margin-top: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
        .name {
          font-size: 18px;
          color: #000;
          padding-top: 19px;
        }
        .text {
          width: 99%;
          padding-top: 8px;
          font-size: 14px;
          line-height: 17px;
          color: #000;
          word-wrap: break-word;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          max-height: 60px;
        }
        &:hover {
          border-color: #29384b;
        }
      }
      li:last-child {
        border-bottom: 1px solid #9daab3;
      }
  }
  .modal {
    .conten {
      width: 853px;
      margin: auto;
      padding: 40px 0;
      .title {
        font-size: 36px;
        font-family: SFProDisplay;
        color: #29384b;
      }
      .text {
        font-size: 14px;
        margin-top: 12px;
        color: #29384b;
      }
      img {
        margin-top: 30px;
      }
    }
  }
}
</style>
<style>
    .modal .el-dialog__body{
        height: 85vh;
        overflow: hidden auto;
    }
</style>
