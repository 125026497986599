<template>
  <PublicLayout>
    <div class="center">
      <div class="title">
        <span class="name">FAQ’s</span>
        <span class="line"></span>
        <span class="help">Help Center</span>
      </div>
      <FaqList />
    </div>
  </PublicLayout>
</template>
<script>
import PublicLayout from "@/components/PublicLayout.vue";
import FaqList from "@c/FaqList";
export default {
  components: {
    PublicLayout,
    FaqList,
  },
};
</script>
<style lang="scss" scoped>
.center {
  padding-bottom: 20px;
  > .title {
    display: flex;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 10px;
    .name {
      font-size: 50px;
      font-family: SFProDisplay;
    }
    .line {
      display: inline-block;
      width: 2px;
      height: 21px;
      background-color: #9daab3;
      margin: 0 22px 0 36px;
    }
    .help {
      font-size: 22px;
    }
  }

}
</style>
<style scoped>
  .center >>> *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .center >>> p, .center >>> h1, .center >>> h2, .center >>> h3, .center >>> h4, .center >>> h5, .center >>> table, .center >>> pre{
    margin: 10px 0;
    line-height: 1.5;
  }

  .center >>> h1{
    font-size: 32px !important;
  }

  .center >>> p{
    font-size: 16px !important;
  }

  .center >>> ul, .center >>> ol{
    margin: 10px 0 10px 20px;
  }

  .center >>> li{
    list-style-type: disc;
  }

  .center >>> ol{
    list-style-type: decimal;
  }
  .center >>> blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }
  .center >>> hr {
    cursor: pointer;
    display: block;
    height: 0px;
    border: 0;
    border-top: 3px solid #ccc;
    margin: 20px 0;
  }
  .center >>> table {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .center >>> table th {
    border-bottom: 2px solid #ccc;
    text-align: center;
    background-color: #f1f1f1;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;
    height: 30px;
  }
  .center >>> table td{
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;
    height: 30px;
  }
</style>
